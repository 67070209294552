
import { computed } from '@nuxtjs/composition-api'

export default {
  name: 'FooterColumn',
  props: {
    title: {
      type: String,
      default: null
    },
    header: {
      type: String,
      default: null
    },
    hideHeader: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  setup (props) {
    const isLink = item => typeof item !== 'string'

    const getComponent = item => isLink(item) ? (item.link.startsWith('/')) ? 'router-link' : 'a' : 'span'
    const getLink = item => isLink(item) ? item.link : null
    const getText = item => isLink(item) ? item.text : item
    const getKey = item => isLink(item) ? `${item.text}-${item.link}` : item
    const getClass = item => isLink(item) ? 'item__text_link' : null

    const hasSubColumns = computed(() => props.items.length > 1)

    return {
      getComponent,
      getLink,
      getText,
      getKey,
      getClass,

      hasSubColumns
    }
  }
}
