
import { defineComponent } from '@nuxtjs/composition-api'

import Copyright from '~/components/Footer/Copyright.vue'
import FooterColumn from '~/components/Footer/FooterColumn.vue'
import FooterColumns from '~/components/Footer/FooterColumns.vue'

import contactData from '~/resources/footer/contactData.json'
import miscData from '~/resources/footer/miscData.json'

export default defineComponent({
  components: {
    Copyright,
    FooterColumn,
    FooterColumns
  },
  setup () {
    return {
      contactData,
      miscData
    }
  }
})
