<template>
  <div class="footer-columns">
    <footer-column
      v-for="col in columns"
      :key="col.title"
      :title="col.title"
      :header="col.header"
      :hide-header="col.hideHeader"
      :items="col.items"
    />
    <slot />
  </div>
</template>

<script>
import FooterColumn from './FooterColumn'

export default {
  name: 'FooterColumns',
  components: {
    FooterColumn
  },
  props: {
    columns: {
      type: Array,
      default: null
    }
  }
}
</script>

<style lang="scss">
.footer-columns {
  display: grid;
  grid-template-columns: var(--footer-columns-template-columns, 1fr);
  gap: 40px 0;

  @include for-tablets {
    --footer-columns-template-columns: repeat(2, 1fr);
    gap: 60px;
  }

  @include for-desktop {
    --footer-columns-template-columns: repeat(4, 1fr);
  }
}
</style>
